export default {
  general: {
    username: 'Nume utilizator',
    password: 'Parolă',
    newpass: 'Parolă Nouă',
    currentpass: 'Parolă Curentă',
    c_password: 'Confirmare Parolă',
    c_newpass: 'Confirmare Parolă Nouă',
    email: 'Adresă Email',
    language: 'Limbă',
    subject: 'Subiect',
    message: 'Mesaj',
    filtername: 'Nume Filtru',
    placeholders: {
      username: 'Introdu nume utilizator',
      password: 'Introdu parola',
      newpass: 'Introdu noua parola',
      currentpass: 'Introdu parola curentă',
      c_password: 'Confirmă parola',
      c_newpass: 'Confirmă noua parolă',
      email: 'Introdu adresa email',
      subject: 'Introdu subiectul',
      message: 'Introdu mesajul tău',
      filtername: 'Introdu numele filtrului'
    },
    errors: {
      language: {
        load: 'Schimbarea limbii a eșuat.'
      }
    },
    here: 'aici',
    ok: 'Ok',
    cancel: 'Renunță',
    on: 'da',
    off: 'nu',
    min: 'Valoare minimă',
    max: 'Valoare maximă',
    years: '{n} an | {n} ani',
    days: '{n} zi | {n} zile',
    type_for_more: 'Caută mai multe...'
  },
  notifications: {
    disabled: '{notifications} sunt blocate. Dacă nu le-ai blocat personal, îți recomandăm să folosești alt browser.',
    notifications: 'Notificările',
    title: 'Setări Notificări',
    form: {
      email: 'Notificări email',
      email_frequency: 'Frecvența notificărilor email',
      browser: 'Notificări browser',
      browser_frequency: 'Frecvența notificărilor push',
      frequency: 'instant | o dată la {n} oră | o dată la {n} ore',
      help: {
        email: 'Vei primi notificări legate de jucători, direct pe email.',
        browser: 'Vei primi notificări legate de jucători, direct în browser.',
        frequency: 'Valoarea recomandată este \'instant\', pentru a nu rata vreun jucător.',
        blocked: 'Ai blocat notificările sau acest browser nu oferă suport pentru notificări.'
      }
    }
  },
  header: {
    menu: {
      home: 'Acasă',
      account: 'Cont',
      signin: 'Autentificare',
      register: 'Înregistrare',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Contact',
      logout: 'Ieșire'
    }
  },
  footer: {
    menu: {
      privacy: 'Confidențialitate (Engleză)',
      terms: 'Termeni și Condiții (Engleză)'
    }
  },
  login: {
    title: 'Autentificare',
    signin: 'Autentificare',
    register: 'Înregistrare',
    forgot: 'Recuperare Parolă',
    form: {
      submit: 'Trimite',
      remember: 'Ține-mă minte'
    },
    info: {
      what: 'Ce face Mercattrick?',
      purpose: 'Scopul principal al Mercattrick este de:',
      purpose1: 'a ajuta <strong>managerii</strong> Hattrick, care vor să <strong>vândă</strong> jucători, în obținerea unei expuneri mai bune a acestor jucători și, implicit, a unui preț de vânzare mai bun;',
      purpose2: 'a ajuta <strong>managerii</strong> Hattrick, care vor să <strong>cumpere</strong> anumite tipuri de jucători, în a primi notificări atunci când acești jucători apar pe lista de transferuri;',
      how: 'Cum Funcționează?',
      buyers: 'Cumpărătorii',
      step1: 'accesează aplicația și își configurează câteva seturi minime de caracteristici ale jucătorilor (filtre) pe baza cărora vor fie notificați atunci când acești jucători sunt puși spre vânzare.',
      sellers: 'Vânzătorii',
      step2: 'pun spre vânzare jucători, în Hattrick, după care, accesând aplicația, își pot "promova" acești jucători.',
      promoting: 'Promovarea',
      step3: 'unui jucător înseamnă că toți "cumpărătorii" interesați de acest tip de jucător sunt notificați că jucătorul este de vânzare.'
    }
  },
  register: {
    title: 'Înregistrare',
    form: {
      registered: 'Contul tău a fost creat cu succes. Click {here} pentru a continua.',
      submit: 'Trimite',
      agreement: 'Prin apăsarea butonului @:register.form.submit accepți {terms} și {privacy} și ești de acord cu primirea ocazională de email-uri referitoare la starea contului tău.',
      tos: 'termenii',
      privacy: 'politica de confidențialitate',
      help: {
        username: 'Acesta nu este numele de utilizator din HT.',
        password: 'Introdu cel putin 6 caractere. Parola ține cont de majuscule.',
        email: 'Folosim această adresă de email pentru a te anunța despre jucătorii noi.'
      }
    }
  },
  recover: {
    title: 'Recuperare Parolă',
    form: {
      recovered: 'Un email conținând linkul de resetare a parolei a fost trimis.',
      submit: 'Recuperează',
      help: {
        email: 'Aceasta este adresa de email asociată contului tău.'
      }
    }
  },
  reset: {
    title: 'Resetare Parolă',
    form: {
      submit: 'Resetează',
      help: {
        email: 'Aceasta trebuie să fie adresa de email asociată contului tău.',
        password: 'Introdu cel putin 6 caractere. Parola ține cont de majuscule.'
      }
    }
  },
  contact: {
    title: 'Contactează-ne',
    form: {
      success: 'Mesajul a fost trimis cu succes.',
      submit: 'Trimite',
      help: {
        email: 'Această adresă de email va fi folosită doar pentru a-ți răspunde la mesaj.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Se sincronizează detaliile utilizatorului...',
      fail: 'Sincronizarea detaliilor a eșuat.',
      retry: 'Încearcă din nou',
      authorize: 'Este necesar să autorizezi acest produs CHPP. Apasă {here} pentru a fi redirecționat către site-ul Hattrick.',
      authorizing: 'Se inițializează procesul de autorizare. Vei fi redirecționat imediat către <strong>hattrick.org</strong> ...',
      finalize: 'Se finalizează procesul de autorizare...'
    },
    system: {
      syncing: 'Se sincronizează detaliile de sistem...',
      fail: 'Sincronizarea detaliilor a eșuat. {retry} sau {skip}!',
      retry: 'Încearcă din nou',
      skip: 'Sari peste'
    }
  },
  account: {
    account: {
      title: 'Setări Cont',
      form: {
        success: 'Contul a fost updatat cu succes.',
        submit: 'Salvează',
        help: {
          username: 'Acesta nu este numele de utilizator din HT.',
          email: 'Folosim această adresă de email pentru a te anunța despre jucătorii noi.'
        }
      }
    },
    login: {
      title: 'Setări Autentificare',
      form: {
        success: 'Parola a fost schimbată cu succes.',
        submit: 'Salvează',
        help: {
          passwords: 'Introdu parola curentă, noua parolă și confirmarea noii parole.'
        }
      }
    },
    privacy: {
      title: 'Setări Confidențialitate',
      form: {
        submit: 'Șterge Contul',
        delete: 'Șterge',
        help: {
          warning: 'Această acțiune este permanentă. Toate datele tale vor fi șterse.',
          confirmation: 'Te rugăm să confirmi că dorești ștergerea permanentă a contului.'
        }
      }
    }
  },
  filter: {
    title: 'Apasă pentru a adăuga/șterge un filtru',
    form: {
      submit: 'Salvează',
      set: 'Setează',
      remove: 'Șterge',
      help: {
        name: 'Atribuie un nume filtrului tău pentru a-l identifica mai ușor.'
      }
    },
    tooltip: {
      save: 'Trebuie să alegi cel puțin un criteriu de filtrare!'
    },
    item: {
      tooltip: 'Apasă \'@:filter.item.show_players\' pentru a vedea lista.',
      show_players: 'Arată Jucători',
      hide_players: 'Ascunde Jucătorii',
      players: 'jucător găsit | jucători găsiți',
      form: {
        processing: 'se procesează',
        fetch: 'Caută direct în HT',
        edit: 'Editează',
        delete: 'Șterge',
        help: {
          confirm: 'Ești sigur că vrei să ștergi acest filtru?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Tranferuri',
      loading: 'Încărcare jucători...',
      none: 'Nu ai niciun jucător la vânzare, la momentul acesta.',
      promoted: 'promovat',
      late: 'prea târziu',
      form: {
        reload: 'Reîncarcă',
        promote: 'Promovează'
      },
      tooltip: 'Promovează-ți jucătorii astfel încât ceilalți să-i vadă.'
    },
    bookmarks: {
      title: 'Bookmark-uri',
      loading: 'Încărcare jucători...',
      none: 'Nu ai niciun jucător preferat, la momentul acesta.',
      form: {
        reload: 'Reîncarcă',
        unbookmark: 'Înlătură',
        help: {
          confirm: 'Ești sigur că vrei să înlături acest bookmark?'
        }
      },
      tooltip: 'Vei primi notificări despre acești jucători atunci când licitația se apropie de final.'
    },
    filters: {
      title: 'Filtre',
      loading: 'Încărcare filtre...',
      none: 'Nu ai filtre momentan. Ar trebui să creezi unul.',
      form: {
        reload: 'Reîncarcă',
        add: 'Adaugă',
        bookmark: 'Bookmark',
        hide: 'Ascunde',
        help: {
          confirm: 'Ești sigur că vrei să ascunzi acest jucător?'
        }
      },
      tooltip: 'Adaugă la bookmark-uri jucători interesanți din aceste liste.'
    }
  },

  player: {
    attributes: {
      name: 'Nume',
      age: 'Vârstă',
      form: 'Formă',
      experience: 'Experiență',
      leadership: 'Lider',
      stamina_skill: 'Rezistență',
      keeper_skill: 'Portar',
      playmaker_skill: 'Construcție',
      scorer_skill: 'Atac',
      passing_skill: 'Pase',
      winger_skill: 'Extremă',
      defender_skill: 'Apărare',
      set_pieces_skill: 'Faze Fixe',
      agreeability: 'Simpatie',
      aggressiveness: 'Agresivitate',
      honesty: 'Cinste',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'ITC',
      salary: 'Salariu',
      price: 'Preț',
      asking_price: 'Preț de pornire',
      highest_bid: 'Ofertă',
      native_country: 'Țară natală',
      native_country_id: 'Țară natală',
      NT_player: 'Jucător Echipă Națională',
      specialties: 'Specialități',
      specialty: 'Specialitate'
    },
    redcard: 'Cartonaș Roșu',
    yellowcard: 'Cartonaș Galben',
    bruised: 'Accidentat Ușor',
    injured: 'Accidentat',
    injury: '{n} săptămână | {n} săptămâni',
    fullage: '{years} ani și {days} zile',
    deadline: 'Termen limită',
    htms: 'HTMS/HTMS28',
    more: 'Mai mult',
    less: 'Mai puțin',
    levels: {
      skill: [
        'inexistent',
        'dezastruos',
        'mizerabil',
        'jalnic',
        'slab',
        'inadecvat',
        'acceptabil',
        'bun',
        'excelent',
        'formidabil',
        'impresionant',
        'sclipitor',
        'magnific',
        'legendar',
        'supranatural',
        'titanic',
        'extraterestru',
        'mitic',
        'magic',
        'utopic',
        'divin',
        'divin (+1)',
        'divin (+2)',
        'divin (+3)',
        'divin (+4)',
        'divin (+5)'
      ],
      agreeability: [
        'tip antipatic',
        'tip controversat',
        'tip plăcut',
        'tip simpatic',
        'tip popular',
        'membru iubit al echipei'
      ],
      aggressiveness: [
        'potolit',
        'calm',
        'echilibrat',
        'temperamental',
        'irascibil',
        'instabil'
      ],
      honesty: [
        'infam',
        'necinstit',
        'cinstit',
        'integru',
        'incoruptibil',
        'angelic'
      ],
      specialty: [
        'fără',
        'tehnic',
        'rapid',
        'puternic',
        'imprevizibil',
        'cap',
        'rezistent',
        '',
        'ajutor'
      ],
      national_team: [
        'niciodată',
        'în prezent nu',
        'în prezent da'
      ],
      any: 'oricare/niciuna'
    },
    tooltips: {
      highest_bid: 'Ultima ofertă ar putea să nu fie actualizată!'
    }
  },
  countries: {
    1: 'Suedia',
    2: 'Anglia',
    3: 'Germania',
    4: 'Italia',
    5: 'Franța',
    6: 'Mexic',
    7: 'Argentina',
    8: 'SUA',
    9: 'Norvegia',
    10: 'Danemarca',
    11: 'Finlanda',
    12: 'Olanda',
    13: 'Oceania',
    14: 'Canada',
    15: 'Scoția',
    16: 'Irlanda',
    17: 'Chile',
    18: 'Columbia',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Peru',
    22: 'Brazilia',
    23: 'Portugalia',
    24: 'Africa de Sud',
    25: 'Japonia',
    26: 'Polonia',
    27: 'India',
    28: 'China',
    29: 'Coreea de Sud',
    30: 'Thailanda',
    31: 'Turcia',
    32: 'Egipt',
    33: 'Austria',
    34: 'Rusia',
    35: 'Spania',
    36: 'România',
    37: 'Islanda',
    38: 'Belgia',
    39: 'Malaezia',
    40: 'Elveția',
    41: 'Singapore',
    42: 'Croația',
    43: 'Serbia',
    44: 'Ungaria',
    45: 'Grecia',
    46: 'Republica Cehă',
    47: 'Estonia',
    48: 'Letonia',
    49: 'Indonezia',
    50: 'Filipine',
    51: 'Israel',
    52: 'Taipeiul Chinez',
    53: 'Hong Kong',
    55: 'Bulgaria',
    56: 'Țara Galilor',
    57: 'Slovenia',
    61: 'Lituania',
    62: 'Ucraina',
    63: 'Bosnia și Herțegovina',
    64: 'Pakistan',
    65: 'Vietnam',
    66: 'Slovacia',
    67: 'Paraguay',
    68: 'Ecuador',
    69: 'Bolivia',
    70: 'Nigeria',
    71: 'Insulele Feroe',
    72: 'Maroc',
    75: 'Arabia Saudită',
    76: 'Tunisia',
    77: 'Costa Rica',
    78: 'Emiratele Arabe Unite',
    79: 'Luxemburg',
    80: 'Iran',
    82: 'Cipru',
    83: 'Republica Dominicană',
    86: 'Senegal',
    87: 'Belarus',
    88: 'Irlanda de Nord',
    89: 'Jamaica',
    90: 'Kenya',
    91: 'Panama',
    92: 'Macedonia',
    93: 'Cuba',
    94: 'Albania',
    95: 'Honduras',
    96: 'El Salvador',
    97: 'Malta',
    98: 'Kârgâzstan',
    99: 'Moldova',
    100: 'Georgia',
    101: 'Andorra',
    102: 'Guatemala',
    103: 'Iordania',
    104: 'Armenia',
    105: 'Trinidad și Tobago',
    121: 'Nicaragua',
    122: 'Kazahstan',
    123: 'Surinam',
    125: 'Liechtenstein',
    126: 'Algeria',
    127: 'Mongolia',
    128: 'Liban',
    129: 'Bahrain',
    130: 'Barbados',
    131: 'Capul Verde',
    132: 'Coasta de Fildeș',
    133: 'Azerbaidjan',
    134: 'Kuweit',
    135: 'Irak',
    136: 'Muntenegru',
    137: 'Angola',
    138: 'Bangladesh',
    139: 'Yemen',
    140: 'Oman',
    142: 'Mozambic',
    143: 'Brunei',
    144: 'Ghana',
    145: 'Cambodgia',
    147: 'Benin',
    148: 'Siria',
    149: 'Qatar',
    150: 'Tanzania',
    153: 'Uganda',
    154: 'Maldive',
    163: 'Uzbekistan',
    165: 'Camerun',
    166: 'Palestina',
    175: 'Sri Lanka',
    177: 'São Tomé și Principe',
    178: 'Curacao',
    179: 'Guam',
    180: 'Comore',
    181: 'RD Congo',
    182: 'Etiopia',
    183: 'Madagascar',
    184: 'Saint Vincent și Grenadine',
    185: 'Botswana',
    186: 'Belize',
    187: 'Zambia',
    188: 'Haiti',
    189: 'Myanmar',
    190: 'Puerto Rico',
    191: 'San Marino',
    192: 'Nepal',
    193: 'Burkina Faso',
    194: 'Grenada',
    196: 'Tahiti',
    197: 'Guyana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Acest câmp poate conține doar litere și cifre.',
      alpha_dash: 'Acest câmp poate conține doar litere, cifre sau caracterele underscore (_) și minus (-).',
      confirmed: 'Câmpul de confirmarea nu este identic.',
      email: 'Acest câmp nu conține o adresă de email validă.',
      max: 'Acest câmp conține prea multe caractere.',
      min: 'Acest câmp conține prea puține caractere.',
      required: 'Acest câmp este obligatoriu.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'Subiectul este obligatoriu.',
        body: 'Conținutul este obligatoriu.',
        username: 'Numele de utilizator este obligatoriu.',
        password: 'Parola este obligatorie.',
        locale: 'Limba este este obligatore.',
        name: 'Numele este obligatoriu.',
        token: 'Token-ul este obligatoriu.'
      },
      integer: {
        native_country_id: 'ID-ul țării trebuie să fie număr.',
        htms_min: 'HTMS-ul minim trebuie să fie număr.',
        htms_max: 'HTMS-ul maxim trebuie să fie număr.',
        htms28_min: 'HTMS28-ul minim trebuie să fie număr.',
        htms28_max: 'HTMS28-ul maxim trebuie să fie număr.',
        tsi_min: 'ITC-ul minim trebuie să fie număr.',
        tsi_max: 'ITC-ul maxim trebuie să fie număr.'
      },
      numeric: {
        age_min: 'Vârsta minimă trebuie să fie număr.',
        age_max: 'Vârsta maximă trebuie să fie număr.',
        salary_min: 'Salariul minim trebuie să fie număr.',
        salary_max: 'Salariu maxim trebuie să fie număr.',
        price_min: 'Prețul minim trebuie să fie număr.',
        price_max: 'Prețul maxim trebuie să fie număr.'
      },
      required: {
        subject: 'Subiectul este obligatoriu.',
        body: 'Conținutul este obligatoriu.',
        username: 'Numele de utilizator este obligatoriu.',
        password: 'Parola este obligatorie.',
        current_password: 'Parola curentă este obligatorie.',
        new_password: 'Noua parolă este obligatorie.',
        email: 'Adresa de email este obligatorie.',
        token: 'Token-ul este obligatoriu.',
        endpoint: 'Parametrul \'endpoint\' este obligatoriu.',
        url: 'Parametrul \'URL\' este obligatoriu.',
        oauth_token: 'Parametrul \'oauth_token\' este obligatoriu.',
        oauth_verifier: 'Parametrul \'oauth_verifier\' este obligatoriu.'
      },
      max: {
        age_min: 'Vârsta minimă nu poate fi mai mare de 100.',
        age_max: 'Vârsta maximă nu poate fi mai mare de 100.',
        htms_min: 'HTMS-ul minim nu poate fi mai mare de 12.000.',
        htms_max: 'HTMS-ul maxim nu poate fi mai mare de 12.000.',
        htms28_min: 'HTMS28-ul minim nu poate fi mai mare de 12.000.',
        htms28_max: 'HTMS28-ul maxim nu poate fi mai mare de 12.000.',
        tsi_min: 'ITC-ul minim nu poate fi mai mare de 16.000.000.',
        tsi_max: 'ITC-ul maxim nu poate fi mai mare de 16.000.000.',
        subject: 'Subiectul conține prea multe caractere.',
        username: 'Numele de utilizator conține prea multe caractere.',
        email: 'Adresa de email conține prea multe caractere.',
        locale: 'Limba conține prea multe caractere.',
        name: 'Numele conține prea multe caractere.',
        token: 'Token-ul conține prea multe caractere.',
        NT_player: 'Opțiunea \'jucător echipă națională\' poate fi maxim 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Parola conține prea puține caractere.',
        age_min: 'Vârsta minimă nu poate fi mai mică de 17.',
        age_max: 'Vârsta maximă nu poate fi mai mică de 17.',
        native_country_id: 'ID-ul țării trebuie să fie mai mare de 0.',
        htms_min: 'HTMS-ul minim trebuie să fie un număr pozitiv.',
        htms_max: 'HTMS-ul maxim trebuie să fie un număr pozitiv.',
        htms28_min: 'HTMS28-ul minim trebuie să fie mai mare de -12.000.',
        htms28_max: 'HTMS28-ul maxim trebuie să fie mai mare de -12.000.',
        tsi_min: 'ITC-ul minim trebuie să fie un număr pozitiv.',
        tsi_max: 'ITC-ul maxim trebuie să fie un număr pozitiv.',
        salary_min: 'Salariul minim trebuie să fie un număr pozitiv.',
        salary_max: 'Salariul maxim trebuie să fie un număr pozitiv.',
        price_min: 'Prețul minim trebuie să fie un număr pozitiv.',
        price_max: 'Prețul maxim trebuie să fie un număr pozitiv.',
        NT_player: 'Opțiunea \'jucător echipă națională\' poate fi minim 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Adresa de email nu este validă.'
      },
      url: {
        url: 'Parametrul \'URL\' nu este valid.'
      },
      in: {
        endpoint: 'Parametrul \'endpoint\' nu este valid.',
        form_min: 'Nivelul minim de formă trebuie să fie între 1 (\'@:player.levels.skill[1]\') și 8 (\'@:player.levels.skill[8]\').',
        form_max: 'Nivelul maxim de formă trebuie să fie între 1 (\'@:player.levels.skill[1]\') și 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'Nivelul minim de simpatie trebuie să fie între 0 (\'@:player.levels.agreeability[0]\') și 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'Nivelul maxim de simpatie trebuie să fie între 0 (\'@:player.levels.agreeability[0]\') și 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'Nivelul minim de agresivitate trebuie să fie între 0 (\'@:player.levels.aggressiveness[0]\') și 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'Nivelul maxim de agresivitate trebuie să fie între 0 (\'@:player.levels.aggressiveness[0]\') și 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'Nivelul minim de cinste trebuie să fie între 0 (\'@:player.levels.honesty[0]\') și 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'Nivelul maxim de cinste trebuie să fie între 0 (\'@:player.levels.honesty[0]\') și 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'Nivelul minim de experiență trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'Nivelul maxim de experiență trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Nivelul minim de lider trebuie să fie între 1 (\'@:player.levels.skill[1]\') și 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Nivelul maxim de lider trebuie să fie între 1 (\'@:player.levels.skill[1]\') și 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'Nivelul minim de rezistență trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'Nivelul maxim de rezistență trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'Nivelul minim de portar trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'Nivelul maxim de portar trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'Nivelul minim de construcție trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'Nivelul maxim de construcție trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'Nivelul minim de atac trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'Nivelul maxim de atac trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'Nivelul minim de pase trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'Nivelul maxim de pase trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'Nivelul minim de extremă trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'Nivelul maxim de extremă trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'Nivelul minim de apărare trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'Nivelul maxim de apărare trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'Nivelul minim de faze fixe trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'Nivelul maxim de faze fixe trebuie să fie între 0 (\'@:player.levels.skill[0]\') și 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'Acest utilizator nu mai există.'
      },
      boolean: {
        promoted: 'Parametrul \'promoted\' trebuie să fie \'true\' sau \'false\'.',
        bookmarked: 'Parametrul \'bookmarked\' trebuie să fie \'true\' sau \'false\'.',
        ignored: 'Parametrul \'ignored\' trebuie să fie \'true\' sau \'false\'.',
        notifications_email: 'Parametrul \'notifications_email\' trebuie să fie \'true\' sau \'false\'.',
        notifications_desktop: 'Parametrul \'notifications_desktop\' trebuie să fie \'true\' sau \'false\'.'
      },
      array: {
        specialties: 'Specialitatea trebuie să conțină un șir de valori.'
      }
    },
    unknown: {
      general: 'A apărut o eroare neprevăzută. Îți sugerăm să reîncarci pagina.'
    },
    network: {
      general: 'Pare că ai probleme de conectare la rețea.',
      maintenance: 'Are loc actualizarea sistemului. Încearcă din nou peste câteva minute.'
    },
    denied: {
      access: 'Access interzis.'
    },
    expired: {
      token: 'Autentificarea a expirat.',
      session: 'Sesiunea a expirat.'
    },
    failed: {
      registration: 'Procesul de înregistrare a eșuat.',
      user_details: 'Obținerea informațiilor despre utilizator a eșuat.',
      player_details: 'Obținerea informațiilor despre jucători a eșuat.',
      filter_fetch: 'Descărcarea de jucători noi a eșuat.',
      password_update: 'Parola nu a fost actualizată.',
      chpp_connection: 'Imposibil de inițiat conexiunea CHPP.',
      chpp_authorization: 'Imposibil de inițiat autorizarea CHPP.'
    },
    invalid: {
      credentials: 'Nume de utilizator sau parolă invalide.',
      token: 'Token-ul de autentificare este invalid.',
      request: 'Cerere invalidă.'
    },
    limit: {
      filters: 'Ai atins limita maximă de filtre pe care le poți crea.'
    },
    missing: {
      token: 'Token-ul de autentificare lipsește.'
    },
    conflict: {
      username: 'Numele de utilizator este deja luat.',
      username_password: 'Numele de utilizator și/sau adresa de email sunt deja luate.'
    }
  }
}
